import React from "react"
import styled from "../../config"
import { Flex, Box } from "reflexbox"
import TextBlock from "../../components/TextBlock"
import Blob from "../../components/Blob"

const Opleidingen = () => (
  <OpleidingenWrapper>
    <Blob cheight="250px" />
    <Flex justifyContent="center" py={5}>
      <h1>Opleidingen</h1>
    </Flex>
    <Box p={3}>
      <ul>
        <li>
          Master Solution-Focused Practitioner - Opleiding in de
          Oplossingsgerichte Cognitieve Interventies en in de Systeemtherapie;
          Korzybski Instituut te Brugge.
        </li>
        <li>
          Bachelor in de Gezinswetenschappen - Studierichting Helpende
          Gesprekken; Hoger Instituut in de Gezinswetenschappen te Brussel.
        </li>
        <li>
          Opleiding tot Hartcoherentie-coach;{" "}
          <StyledLink
            href="https://www.louvanie.be/hch-netwerk-coaches"
            target="_blank"
            rel="noreferrer noopener"
          >
            Louvanie
          </StyledLink>{" "}
          te Willebroek. Erkend{" "}
          <StyledLink
            href="https://nerva.coach"
            target="_blank"
            rel="noopener noreferrer"
          >
            Nervacoach
          </StyledLink>
          .
        </li>
        <li>
          Certificaat{" "}
          <StyledLink
            href="https://brainspotting.be"
            target="_blank"
            rel="noopener noreferrer"
          >
            brainspotting
          </StyledLink>{" "}
          fase 1 & fase 2; Educatieve Academie te Berchem
        </li>
        <li>
          Certificaat{" "}
          <StyledLink
            href="https://brainspotting.be"
            target="_blank"
            rel="noopener noreferrer"
          >
            brainspotting
          </StyledLink>{" "}
          fase 3; online opleiding van David Grand (ontdekking en ontwikkeling
          van de brainspotting-methode)
        </li>
        <li>
          Certificaat brainspotting "5-daagse Intensive"; docent Marie José
          Boon, internationaal Brainspot supervisor, practitioner en trainer,
          Mechelen.
        </li>
        <li>
          Certificaat Polyvagaal theorie: workshop Rust in je brein, veiligheid
          in je lijf; Westerlo.
        </li>
        <li>
          In het kader van levenslang leren volg ik regelmatig opleidingen of
          workshops die aan mijn beroep gerelateerd zijn.
        </li>
      </ul>

      <h2>Beroepsverenigingen</h2>
      <ul>
        <li>
          <StyledLink
            href="https://vvdo.be"
            target="_blank"
            rel="noreferrer noopener"
          >
            VVDO
          </StyledLink>{" "}
          (Vlaamse Vereniging van Deskundigen in de Oplossingsgerichte,
          Cognitieve en Systemische Therapie, Opvoedkunde en Coaching).
        </li>
        <li>
          <StyledLink
            href="https://www.louvanie.be/hch-netwerk-coaches"
            target="_blank"
            rel="noopener noreferrer"
          >
            Netwerk Louvanie Hartcoherentie Coaches.
          </StyledLink>{" "}
        </li>
        <li>
          <StyledLink
            href="https://brainspotting.be"
            target="_blank"
            rel="noopener noreferrer"
          >
            Brainspotting
          </StyledLink>
        </li>
      </ul>
      <TextBlock>
        Ik respecteer het beroepsgeheim en de ethische beroepscode. Dit houdt in
        dat ik geen persoonlijke informatie mag doorgeven aan derden zonder uw
        uitdrukkelijke toestemming.
      </TextBlock>
      <TextBlock>Ondernemingsnummer: 0640.818.523</TextBlock>
    </Box>
  </OpleidingenWrapper>
)

export default Opleidingen

const OpleidingenWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px;
`

const StyledLink = styled.a`
  text-decoration: underline;
  color: #00628f;
`
