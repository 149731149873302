import React from "react"

import GlobalCollection from "../config/GlobalCollection"
import Opleidingen from "../modules/opleidingen/Opleidingen"

const OpleidingenPage = () => (
  <GlobalCollection>
    <Opleidingen />
  </GlobalCollection>
)

export default OpleidingenPage
